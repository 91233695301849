import get from 'lodash/get';
import has from 'lodash/has';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';

export default class Body extends React.Component {
	constructor(props) {
		super(props);
		this.handleRouteChange = this.handleRouteChange.bind(this);
	}

	async componentDidMount() {
		let offsetY = 0;
		let ticking = false;

		window.addEventListener('scroll', function (e) {
			offsetY = window.scrollY;
			if (!ticking) {
				window.requestAnimationFrame(function () {
					handleHeader(offsetY);
					ticking = false;
				});
				ticking = true;
			}
		});

		function handleHeader(scrollPos) {
			if (scrollPos > 0) {
				document.body.classList.add('has--scrolled');
			} else {
				document.body.classList.remove('has--scrolled');
			}
		}

		Router.events.on('routeChangeStart', this.handleRouteChange);
	}

	componentWillUnmount() {
		Router.events.off('routeChangeStart', this.handleRouteChange);
	}

	handleRouteChange() {}

	render() {
		const page = get(this.props, 'page');
		const title = (has(page, 'title') ? get(page, 'title') + ' - ' : '') + get(this.props, 'config.title');
		return (
			<React.Fragment>
				<Head>
					<title>{title}</title>
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width, initialScale=1.0" />
					<meta name="google" content="notranslate" />
					<link
						href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i&display=optional"
						rel="stylesheet"
					/>
				</Head>
				<div id="page" className={'cms-single-page site palette-' + get(this.props, 'config.palette')}>
					<main id="content" className="site-content">
						{this.props.children}
					</main>
				</div>
			</React.Fragment>
		);
	}
}
