import get from 'lodash/get';
import has from 'lodash/has';
import React from 'react';

import { LayoutSanity } from '../components';
import PostFooter from '../components/PostFooter';
import { markdownify } from '../utils';

export default class Post extends React.Component {
	render() {
		const page = get(this.props, 'page');
		return (
			<LayoutSanity {...this.props}>
				<div className="outer">
					<div className="inner-medium">
						<article className="post post-full">
							<header className="post-header">
								<h1 className="post-title">{get(page, 'title')}</h1>
							</header>
							{has(page, 'image') && (
								<div className="post-thumbnail">
									<img src={get(page, 'image')} alt={get(page, 'title')} />
								</div>
							)}
							{has(page, 'subtitle') && <div className="post-subtitle">{get(page, 'subtitle')}</div>}
							{has(page, 'content') && (
								<div className="post-content">{markdownify(get(page, 'content'))}</div>
							)}
							<PostFooter post={page} dateFormat="%A, %B %e, %Y" />
						</article>
					</div>
				</div>
			</LayoutSanity>
		);
	}
}
