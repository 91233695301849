import Link from 'next/link';
import React from 'react';

const Custom404 = () => {
	return (
		<div className="page empty-results not-found-404">
			<div className="container">
				<div className="info">
					<h6 className="page-title">
						<i className="fal fa-exclamation-circle"></i> Page Not Found
					</h6>

					<div className="help">
						<p>The link you followed is broken, or the page has been removed. Here is what you can do:</p>

						<div className="help-elements">
							<Link href={`/`} as={`/`}>
								<a className="help-element">
									<i className="fas fa-home"></i>
									<p>Go to Homepage</p>
								</a>
							</Link>

							<a className="help-element" href="tel:+18002288379">
								<i className="fad fa-phone"></i>
								<p>Call help at 1-800-228-8379</p>
							</a>
							<a
								className="help-element"
								href="mailto:info@1800battery.com?Subject=Inquiry%20from%20Website">
								<i className="fad fa-envelope" aria-hidden="true"></i>{' '}
								<p>Get in touch with our support</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export async function getStaticProps() {
	return { props: {} };
}

export default Custom404;
