import blog from './blog';
import blog_category from './blog_category';
import landing from './landing';
import page from './page';
import post from './post';

export default {
	landing,
	page,
	blog,
	blog_category,
	post,
};
