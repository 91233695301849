import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment-strftime';
import React from 'react';

import { categoryUrl, Link } from '../utils';

export default class PostFooter extends React.Component {
	renderCategoryLinks(post) {
		const categories = get(post, 'categories');
		if (isEmpty(categories)) {
			return null;
		}
		return (
			<div>
				Categories:
				{map(categories, (category, index) => {
					return (
						<span key={index}>
							{index > 0 ? ', ' : ' '}
							<Link href={categoryUrl(category)}>{category.title}</Link>
						</span>
					);
				})}
			</div>
		);
	}

	render() {
		const post = this.props.post;
		const dateFormat = get(this.props, 'dateFormat', '%B %d, %Y');
		return (
			<footer className="post-meta">
				<time className="published" dateTime={moment(get(post, 'date')).strftime('%Y-%m-%d %H:%M')}>
					{moment(get(post, 'date')).strftime(dateFormat)}
				</time>
				{has(post, 'author') &&
					', By ' + get(post, 'author.first_name') + ' ' + get(post, 'author.last_name', '')}
				{this.renderCategoryLinks(post)}
			</footer>
		);
	}
}
