import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import coverLeft from '../static/images/landing/cover-provider-left.jpg';
import coverRight from '../static/images/landing/cover-provider-right.jpg';
import Action from './Action';

export default class SectionHero extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section
				id={get(section, 'section_id')}
				className="provider-section cover"
				style={{
					background: `url(${coverLeft})`,
				}}>
				<div
					className="cover-inner"
					style={{
						background: `url(${coverRight})`,
					}}>
					<div className="shade">
						<div className="container">
							<div className="inner">
								<div className="content">
									{get(section, 'title') && <h2>{get(section, 'title')}</h2>}
									<p>
										Becoming a mobile provider for 1-800-Battery can help you make someone&apos;s
										day.
									</p>

									{get(section, 'actions') && (
										<p className="block-buttons">
											{map(get(section, 'actions'), (action, actionIdx) => (
												<Action
													key={actionIdx}
													action={action}
													className="button white large"
												/>
											))}
										</p>
									)}

									<div className="border-line"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
