import get from 'lodash/get';
import map from 'lodash/map';
import Image from 'next/image';
import React from 'react';

import { htmlToReact, Link, safePrefix } from '../utils';
import Action from './Action';
import Social from './Social';
import SubscribeForm from './SubscribeForm';

export default class Footer extends React.Component {
	render() {
		const config = get(this.props, 'config');
		return (
			<footer id="colophon" className="site-footer">
				<div className="footer-top outer">
					<div className="inner">
						<div className="footer-widgets">
							<div className="widget footer-branding">
								{get(config, 'footer.logo_img') ? (
									<p className="site-logo">
										<Link href={safePrefix('/')}>
											<Image
												objectFit="contain"
												width="200px"
												height="64px"
												src={get(config, 'footer.logo_img')}
												alt="Logo"
											/>
										</Link>
									</p>
								) : (
									<p className="site-title">
										<Link href={safePrefix('/')}>{get(config, 'header.title')}</Link>
									</p>
								)}
								{get(config, 'footer.tagline') && (
									<p className="site-description">{get(config, 'footer.tagline')}</p>
								)}
							</div>
							<div>
								{get(config, 'custom_object.question') && (
									<div>
										q: {get(config, 'custom_object.question')} a:{' '}
										{get(config, 'custom_object.answer')}
									</div>
								)}
								{get(config, 'partner_companies') && (
									<div>
										{map(get(config, 'partner_companies'), (company, companyIdx) => (
											<li key={companyIdx}>
												{company.company_name} - {company.company_description}
											</li>
										))}
									</div>
								)}
							</div>
							{((get(config, 'footer.nav_links') && get(config, 'footer.has_nav')) ||
								get(config, 'footer.has_social')) && (
								<nav className="widget footer-navigation">
									<div className="footer-nav-inside">
										{get(config, 'footer.nav_links') && get(config, 'footer.has_nav') && (
											<div className="secondary-nav">
												<h2 className="widget-title">{get(config, 'footer.nav_title')}</h2>
												<ul className="secondary-menu">
													{map(get(config, 'footer.nav_links'), (action, actionIdx) => (
														<li key={actionIdx}>
															<Action action={action} />
														</li>
													))}
												</ul>
											</div>
										)}
										{get(config, 'footer.has_social') && (
											<div className="social-nav">
												<h2 className="widget-title">{get(config, 'footer.social_title')}</h2>
												<Social socialLinks={get(config, 'footer.social_links')} />
											</div>
										)}
									</div>
								</nav>
							)}
							{get(config, 'footer.has_subscribe') && (
								<div className="widget footer-subscribe">
									<h2 className="widget-title">{get(config, 'footer.subscribe_title')}</h2>
									{get(config, 'footer.subscribe_content') && (
										<p>{htmlToReact(get(config, 'footer.subscribe_content'))}</p>
									)}
									<SubscribeForm {...this.props} />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="site-info outer">
					<div className="inner">
						{htmlToReact(get(config, 'footer.content'))}
						&nbsp;
						{map(get(config, 'footer.links'), (link, linkIdx) => (
							<React.Fragment key={linkIdx}>
								<Action key={linkIdx} action={link} />.
							</React.Fragment>
						))}
					</div>
				</div>
			</footer>
		);
	}
}
