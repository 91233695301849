import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';

import { classNames } from '../utils';
import Action from './Action';

export default class CtaButtons extends React.Component {
	render() {
		const actions = get(this.props, 'actions');
		if (isEmpty(actions)) {
			return null;
		}
		return (
			<p className="block-buttons">
				{map(actions, (action, actionIdx) => {
					return (
						<Action
							key={actionIdx}
							action={action}
							className={classNames('button', { secondary: !get(action, 'primary') })}
						/>
					);
				})}
			</p>
		);
	}
}
