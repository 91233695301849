import get from 'lodash/get';
import map from 'lodash/map';
import trim from 'lodash/trim';
import Image from 'next/image';
import Router from 'next/router';
import React from 'react';

import { Link, safePrefix } from '../utils';
import Action from './Action';

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleRouteChange = this.handleRouteChange.bind(this);
		this.menuOpenRef = React.createRef();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize, true);
		Router.events.on('routeChangeStart', this.handleRouteChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize, true);
		Router.events.off('routeChangeStart', this.handleRouteChange);
	}

	handleWindowResize() {
		if (get(this.menuOpenRef, 'current.offsetParent') === null) {
			document.body.classList.remove('menu--opened');
		}
	}

	handleRouteChange() {
		document.body.classList.remove('menu--opened');
	}

	handleMenuOpen(event) {
		event.preventDefault();
		document.body.classList.add('menu--opened');
	}

	handleMenuClose(event) {
		event.preventDefault();
		document.body.classList.remove('menu--opened');
	}

	render() {
		const config = get(this.props, 'config');
		const page = get(this.props, 'page');
		return (
			<header id="masthead" className="site-header outer">
				<div className="inner">
					<div className="site-header-inside">
						<div className="site-branding">
							{get(config, 'header.logo_img') && (
								<p className="site-logo">
									<Link href={safePrefix('/')}>
										<Image
											objectFit="contain"
											width="200px"
											height="64px"
											src={get(config, 'header.logo_img')}
											alt="Logo"
										/>
									</Link>
								</p>
							)}
							{get(page, '_type') === 'landing' || get(page, '_type') === 'blog' ? (
								<h1 className="site-title">
									<Link href={safePrefix('/')}>{get(config, 'header.title')}</Link>
								</h1>
							) : (
								<p className="site-title">
									<Link href={safePrefix('/')}>{get(config, 'header.title')}</Link>
								</p>
							)}
						</div>
						{get(config, 'header.nav_links') && get(config, 'header.has_nav') && (
							<React.Fragment>
								<nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
									<div className="site-nav-inside">
										<button
											id="menu-close"
											className="menu-toggle"
											onClick={this.handleMenuClose.bind(this)}>
											<span className="screen-reader-text">Open Menu</span>
											<span className="icon-close" aria-hidden="true" />
										</button>
										<ul className="menu">
											{map(get(config, 'header.nav_links'), (action, actionIdx) => (
												<li
													key={actionIdx}
													className={
														'menu-item' +
														(trim(get(this.props, 'path'), '/') ===
														trim(get(action, 'url'), '/')
															? ' current-menu-item'
															: '') +
														(get(action, 'primary') ? ' menu-button' : '')
													}>
													<Action
														action={action}
														className={get(action, 'primary') ? 'button' : ''}
													/>
												</li>
											))}
										</ul>
									</div>
								</nav>
								<button
									id="menu-open"
									className="menu-toggle"
									ref={this.menuOpenRef}
									onClick={this.handleMenuOpen.bind(this)}>
									<span className="screen-reader-text">Close Menu</span>
									<span className="icon-menu" aria-hidden="true" />
								</button>
							</React.Fragment>
						)}
					</div>
				</div>
			</header>
		);
	}
}
