import assign from 'lodash/assign';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import React from 'react';

import BlogLayout from './blog';

export default class Blog extends React.Component {
	render() {
		const category = get(this.props, 'page.slug');
		let posts = get(this.props, 'posts', []);
		posts = filter(posts, post => {
			const postCategories = map(get(post, 'categories', []), category => category.slug);
			return includes(postCategories, category);
		});
		posts = orderBy(posts, ['date'], ['desc']);
		const props = assign({}, this.props, { posts: posts });
		return <BlogLayout {...props} />;
	}
}
