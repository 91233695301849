import NotFound from '@pages/404';
import getGoogleCriteronProps from '@src/functions/getGoogleCriteronProps';
import pageLayouts from '@src/layouts';
import { setLocation } from '@src/redux/actions/geolocation';
import get from 'lodash/get';
import reject from 'lodash/reject';
//export const config = { amp: true }  --  TODO: NEED TO TEST AMP SETTINGS TO GET AMP - Current Error "The attribute 'href' in tag 'link rel=stylesheet for fonts' is set to the invalid value '/static/nprogress.css'""
import React from 'react';
import { connect } from 'react-redux';
import { sourcebitDataClient } from 'sourcebit-target-next';
import { withRemoteDataUpdates } from 'sourcebit-target-next/with-remote-data-updates'; // Only dev live update fires..

class Page extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			critChecked: false,
			critId: null,
		};
	}

	async checkCrit(crit) {
		if (!this.critChecked) {
			if (crit) {
				const zip_codes = await getGoogleCriteronProps(crit);

				if (zip_codes && zip_codes.zipCodes && zip_codes.zipCodes[0]) {
					const payload = {
						latitude: zip_codes.zipCodes[0].latitude,
						longitude: zip_codes.zipCodes[0].longitude,
						zipcode: zip_codes.zipCodes[0].zipcode,
						address: null,
						city: zip_codes.zipCodes[0].cityName,
						state: zip_codes.zipCodes[0].stateAbbr,
						country: 'US',
						formatted_address: `${zip_codes.zipCodes[0].cityName}, ${zip_codes.zipCodes[0].stateAbbr}`,
					};
					await this.props.setLocation(payload);
				}
				this.setState({ critId: crit, critChecked: true });
			}
		}
	}

	async componentDidMount() {
		if (this.props.router) {
			var { crit } = this.props.router.query;
			if (crit) {
				this.checkCrit(crit);
			}
		}
	}

	render() {
		const PageLayout = pageLayouts[get(this.props, 'page._type')];
		if (PageLayout) {
			return <PageLayout {...this.props} />;
		}
		return <NotFound />;
	}
}

export async function getStaticPaths() {
	const paths = await sourcebitDataClient.getStaticPaths();
	return { paths: reject(paths, path => path === '/'), fallback: true };
}

export async function getStaticProps({ params }) {
	const pagePath = '/' + params.slug.join('/');
	const props = await sourcebitDataClient.getStaticPropsForPageAtPath(pagePath);
	return { props };
}

const mapStateToProps = state => ({
	geolocation: state.geolocation,
});

export default withRemoteDataUpdates(
	connect(mapStateToProps, {
		setLocation,
	})(Page)
);
