import get from 'lodash/get';
import map from 'lodash/map';
import Image from 'next/image';
import React from 'react';

import { markdownify } from '../utils';

export default class SectionFeatures extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section id={get(section, 'section_id')} className={'provider-section features'}>
				<div className="container">
					<div className="header">
						{get(section, 'title') && <h2>{get(section, 'title')}</h2>}
						{get(section, 'subtitle') && <p className="subtitle">{get(section, 'subtitle')}</p>}
					</div>
					{get(section, 'features') && (
						<div className="features-inner">
							{map(get(section, 'features'), (feature, featureIndex) => (
								<div key={featureIndex} className="item">
									<div className="item-inner">
										{get(feature, 'image') && (
											<div className="icon">
												<Image
													width="200px"
													height="200px"
													objectFit="contain"
													src={get(feature, 'image')}
													alt={get(feature, 'title')}
												/>
											</div>
										)}
										<div className="content">
											<h3 className="title">{get(feature, 'title')}</h3>
											<div className="block-copy">{markdownify(get(feature, 'content'))}</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</section>
		);
	}
}
