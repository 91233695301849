import CtaButtons from './CtaButtons';
import Footer from './Footer';
import Header from './Header';
import LayoutSanity from './LayoutSanity';
import SectionContact from './SectionContact';
import SectionContent from './SectionContent';
import SectionCta from './SectionCta';
import SectionFaq from './SectionFaq';
import SectionFeatures from './SectionFeatures';
import SectionHero from './SectionHero';
import SectionPosts from './SectionPosts';
import SectionPricing from './SectionPricing';
import SectionReviews from './SectionReviews';
import Social from './Social';
import SubscribeForm from './SubscribeForm';

export {
	CtaButtons,
	Footer,
	Header,
	SectionContact,
	SectionContent,
	SectionCta,
	SectionFaq,
	SectionFeatures,
	SectionHero,
	SectionPosts,
	SectionPricing,
	SectionReviews,
	Social,
	SubscribeForm,
	LayoutSanity,
};

export default {
	CtaButtons,
	Footer,
	Header,
	SectionContact,
	SectionContent,
	SectionCta,
	SectionFaq,
	SectionFeatures,
	SectionHero,
	SectionPosts,
	SectionPricing,
	SectionReviews,
	Social,
	SubscribeForm,
	LayoutSanity,
};
