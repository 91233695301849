import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import take from 'lodash/take';
import Image from 'next/image';
import React from 'react';

import { Link, markdownify, postUrl } from '../utils';
import PostFooter from './PostFooter';

const SectionPosts = props => {
	const section = get(props, 'section');
	const posts = take(orderBy(get(props, 'posts', []), ['date'], ['desc']), 3);
	return (
		<section
			id={get(section, 'section_id')}
			className={'block posts-block bg-' + get(section, 'background') + ' outer'}>
			<div className="block-header inner-small">
				{get(section, 'title') && <h2 className="block-title">{get(section, 'title')}</h2>}
				{get(section, 'subtitle') && <p className="block-subtitle">{get(section, 'subtitle')}</p>}
			</div>
			<div className="inner">
				<div className="post-feed">
					{map(posts, (post, postIdx) => (
						<article key={postIdx} className="post post-card">
							<div className="post-card-inside">
								{get(post, 'thumb_image') && (
									<Link href={postUrl(post)} className="post-card-thumbnail">
										<Image
											width="100px"
											height="100px"
											objectFit="contain"
											className="thumbnail"
											src={get(post, 'thumb_image')}
											alt={get(post, 'title')}
										/>
									</Link>
								)}
								<div className="post-card-content">
									<header className="post-header">
										<h3 className="post-title">
											<Link href={postUrl(post)} rel="bookmark">
												{get(post, 'title')}
											</Link>
										</h3>
									</header>
									<div className="post-excerpt">{markdownify(get(post, 'excerpt'))}</div>
									<PostFooter post={post} />
								</div>
							</div>
						</article>
					))}
				</div>
			</div>
		</section>
	);
};

export default SectionPosts;
