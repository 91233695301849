import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import Action from './Action';

export default class SectionCta extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section id={get(section, 'section_id')} className="block cta-block bg-accent outer">
				<div className="inner-large">
					<div className="grid">
						<div className="cell block-content">
							{get(section, 'title') && <h2 className="block-title">{get(section, 'title')}</h2>}
							{get(section, 'subtitle') && <p className="block-subtitle">{get(section, 'subtitle')}</p>}
						</div>
						{get(section, 'actions') && (
							<div className="cell block-buttons">
								{map(get(section, 'actions'), (action, actionIdx) => (
									<Action key={actionIdx} action={action} className="button white large" />
								))}
							</div>
						)}
					</div>
				</div>
			</section>
		);
	}
}
