import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import { markdownify } from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionPricing extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section
				id={get(section, 'section_id')}
				className={'block pricing-block bg-' + get(section, 'background') + ' outer'}>
				<div className="block-header inner-small">
					{get(section, 'title') && <h2 className="block-title">{get(section, 'title')}</h2>}
					{get(section, 'subtitle') && <p className="block-subtitle">{get(section, 'subtitle')}</p>}
				</div>
				{get(section, 'pricing_plans') && (
					<div className="inner">
						<div className="grid">
							{map(get(section, 'pricing_plans'), (pricingPlan, pricingPlanIdx) => (
								<div
									key={pricingPlanIdx}
									className={'cell plan' + (get(pricingPlan, 'highlight') ? ' highlight' : '')}>
									<div className="plan-inside">
										<h3 className="plan-name">{get(pricingPlan, 'title')}</h3>
										{get(pricingPlan, 'price') && (
											<div className="plan-price">{get(pricingPlan, 'price')}</div>
										)}
										<div className="plan-details">{markdownify(get(pricingPlan, 'details'))}</div>
										<CtaButtons actions={get(pricingPlan, 'actions')} />
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</section>
		);
	}
}
