import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import { markdownify } from '../utils';

export default class SectionFaq extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section
				id={get(section, 'section_id')}
				className={'block faq-block bg-' + get(section, 'background') + ' outer'}>
				<div className="inner-medium">
					<div className="block-header">
						{get(section, 'title') && <h2 className="block-title">{get(section, 'title')}</h2>}
						{get(section, 'subtitle') && <p className="block-subtitle">{get(section, 'subtitle')}</p>}
					</div>
					{get(section, 'faq_items') && (
						<dl className="faq-accordion">
							{map(get(section, 'faq_items'), (faqItem, faqItemIndex) => (
								<React.Fragment key={faqItemIndex}>
									<dt className="accordion-header">
										<button className="accordion-trigger">
											<div className="accordion-title">{get(faqItem, 'question')}</div>
											<div className="accordion-icon icon-plus" />
										</button>
									</dt>
									<dd className="accordion-panel">
										<div className="accordion-content">{markdownify(get(faqItem, 'answer'))}</div>
									</dd>
								</React.Fragment>
							))}
						</dl>
					)}
				</div>
			</section>
		);
	}
}
