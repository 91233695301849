import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import map from 'lodash/map';
import upperFirst from 'lodash/upperFirst';
import React from 'react';

import components, { LayoutSanity } from '../components';

export default class Landing extends React.Component {
	render() {
		// TODO: caps - first cap letter can be done via css (V.T. 31.1.2022)
		return (
			<LayoutSanity {...this.props}>
				{map(get(this.props, 'page.sections'), (section, section_idx) => {
					const component = upperFirst(camelCase(get(section, '_type')));
					const Component = components[component];
					return <Component key={section_idx} {...this.props} section={section} />;
				})}
			</LayoutSanity>
		);
	}
}
