import get from 'lodash/get';
import Image from 'next/image';
import React from 'react';

import { markdownify } from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionContent extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section
				id={get(section, 'section_id')}
				className={'block text-block bg-' + get(section, 'background') + ' outer'}>
				<div className="inner">
					<div className="grid">
						{get(section, 'image') && (
							<div className="cell block-preview">
								<Image
									width="200px"
									height="200px"
									objectFit="contain"
									src={get(section, 'image')}
									alt={get(section, 'title')}
								/>
							</div>
						)}
						<div className="cell block-content">
							{get(section, 'title') && (
								<h2 className="block-title underline">{get(section, 'title')}</h2>
							)}
							<div className="block-copy">{markdownify(get(section, 'content'))}</div>
							<CtaButtons actions={get(section, 'actions')} />
						</div>
					</div>
				</div>
			</section>
		);
	}
}
