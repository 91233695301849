import get from 'lodash/get';
import map from 'lodash/map';
import Image from 'next/image';
import React from 'react';

import { htmlToReact } from '../utils';

export default class SectionReviews extends React.Component {
	render() {
		const section = get(this.props, 'section');
		return (
			<section
				id={get(section, 'section_id')}
				className={'block reviews-block bg-' + get(section, 'background') + ' outer'}>
				<div className="block-header inner-small">
					{get(section, 'title') && <h2 className="block-title">{get(section, 'title')}</h2>}
					{get(section, 'subtitle') && <p className="block-subtitle">{get(section, 'subtitle')}</p>}
				</div>
				{get(section, 'reviews') && (
					<div className="inner">
						<div className="grid">
							{map(get(section, 'reviews'), (review, reviewIdx) => (
								<blockquote key={reviewIdx} className="cell review">
									<div className="review-inside">
										<p className="review-text">{htmlToReact(get(review, 'content'))}</p>
										<footer className="review-footer">
											{get(review, 'avatar') && (
												<Image
													width="100px"
													height="100px"
													objectFit="contain"
													className="review-avatar"
													src={get(review, 'avatar')}
													alt="Author avatar"
												/>
											)}
											<cite className="review-author">{get(review, 'author')}</cite>
										</footer>
									</div>
								</blockquote>
							))}
						</div>
					</div>
				)}
			</section>
		);
	}
}
