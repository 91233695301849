import get from 'lodash/get';
import has from 'lodash/has';
import React from 'react';

import { LayoutSanity } from '../components';
import { markdownify } from '../utils';

// TODO: Generalize function and reuse (V.T. 31.1.2022)
export default class Page extends React.Component {
	render() {
		// console.log('www', this.props);
		const page = get(this.props, 'page');
		return (
			<LayoutSanity {...this.props}>
				<div className="block">
					<div className="inner-medium">
						<header>
							<h1 className="post-title">{get(page, 'title')}</h1>
						</header>

						<article className="post post-full">
							<div className="content">
								{has(page, 'image') && (
									<div className="post-thumbnail">
										<img src={get(page, 'image')} alt={get(page, 'title')} />
									</div>
								)}

								<div className="text">
									{has(page, 'subtitle') && (
										<h2 className="post-subtitle">{get(page, 'subtitle')}</h2>
									)}
									{has(page, 'content') && (
										<div className="post-content">{markdownify(get(page, 'content'))}</div>
									)}
								</div>
							</div>
						</article>
					</div>
				</div>
			</LayoutSanity>
		);
	}
}
