import get from 'lodash/get';
import React from 'react';

import { Link, safePrefix } from '../utils';

export default class Action extends React.Component {
	render() {
		const { action } = this.props;
		const targetProps = get(action, 'new_window') ? { target: '_blank', rel: 'noopener' } : null;
		var renderButton = false;

		var hrefAction = safePrefix(get(action, 'url'));
		if (action.url.includes('tel:') || action.url.includes('mailto:')) {
			hrefAction = get(action, 'url').replace(/"/g, '');
			renderButton = true;
		}
		return renderButton ? (
			<a href={hrefAction}>
				<button className="btn">{get(action, 'label')}</button>
			</a>
		) : (
			<Link href={hrefAction} {...targetProps} {...this.props.rest}>
				{get(action, 'label')}
			</Link>
		);
	}
}
