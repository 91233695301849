import get from 'lodash/get';
import map from 'lodash/map';
import React from 'react';

import Action from './Action';

export default class Social extends React.Component {
	render() {
		return (
			<ul className="social-links">
				{map(get(this.props, 'socialLinks'), (action, actionIdx) => (
					<li key={actionIdx}>
						<Action action={action} />
					</li>
				))}
			</ul>
		);
	}
}
