import get from 'lodash/get';
import has from 'lodash/has';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import React from 'react';

import { LayoutSanity } from '../components';
import PostFooter from '../components/PostFooter';
import { Link, markdownify, postUrl } from '../utils';

// TODO: Generalize function and reuse (V.T. 31.1.2022)

export default class Blog extends React.Component {
	render() {
		const posts = orderBy(get(this.props, 'posts', []), ['date'], ['desc']);
		return (
			<LayoutSanity {...this.props}>
				<div className="outer">
					<div className="inner">
						<div className="post-feed">
							{map(posts, (post, postIdx) => (
								<article key={postIdx} className="post post-card">
									<div className="post-card-inside">
										{has(post, 'thumb_image') && (
											<Link href={postUrl(post)} className="post-card-thumbnail">
												<img
													className="thumbnail"
													src={get(post, 'thumb_image')}
													alt={get(post, 'title')}
												/>
											</Link>
										)}
										<div className="post-card-content">
											<header className="post-header">
												<h2 className="post-title">
													<Link href={postUrl(post)} rel="bookmark">
														{get(post, 'title')}
													</Link>
												</h2>
											</header>
											<div className="post-excerpt">{markdownify(get(post, 'excerpt'))}</div>
											<PostFooter post={post} />
										</div>
									</div>
								</article>
							))}
						</div>
					</div>
				</div>
			</LayoutSanity>
		);
	}
}
